<template>
    <div class="personInfo">
        <div class="oraganizationInfos">
            <el-card>
                <template #header>
                    <span>个人信息</span>
                </template>
                <div class="oraganizationBaseInfo">
                    <div class="picInfo">
                        <img :src="avatarImage">
                    </div>
                    <div class="oraganizationBaseOtherInfos">
                        <div class="oraganizationInfoItem">
                            <span class="iconfont icon-orgLocationManageSystem">所属机构</span>
                            <span>{{oraganizationBaseOtherInfos.corp}}</span>
                        </div>
                        <el-divider></el-divider>

                        <el-divider></el-divider>
                        <div class="oraganizationInfoItem">
                            <span class="iconfont icon-zhiwei">职位</span>
                            <span>{{oraganizationBaseOtherInfos.position}}</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="oraganizationInfoItem">
                            <span class="iconfont icon-riqi">出生日期</span>
                            <span>{{oraganizationBaseOtherInfos.birthdate}}</span>
                        </div>
                        <el-divider></el-divider>
                        <div class="oraganizationInfoItem">
                            <span class="iconfont icon-xingbie">性别</span>
                            <span>{{oraganizationBaseOtherInfos.sex}}</span>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="otherInfos">
            <el-card>
                <template #header>
                    <span>基本资料</span>
                </template>
                <div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="基本信息" name="baseInfo">
                            <div class="baseInfoForm">
                                <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRules" ref="baseInfoFormRef" label-width="100px">
                                    <el-form-item label="昵称" prop="nickName">
                                        <el-input @input="e => baseInfoForm.nickName = validForbid(e)" v-model="baseInfoForm.nickName" clearable maxlength="30"/>
                                    </el-form-item>
                                    <el-form-item label="手机号码" prop="mobile">
                                        <el-input @input="e => baseInfoForm.mobile = validForbid(e)" v-model="baseInfoForm.mobile" clearable maxlength="11"/>
                                    </el-form-item>
                                    <el-form-item label="电子邮箱" prop="email">
                                        <el-input @input="e => baseInfoForm.email = validForbid(e)" v-model="baseInfoForm.email" clearable maxlength="40"/>
                                    </el-form-item>
                                    <el-form-item v-if="canSave">
                                        <el-button type="primary" class="iconfont icon-baocun" @click="submitForm('baseInfoForm')">保存</el-button>
                                        <el-button class="iconfont icon-chongzhi" @click="resetForm('baseInfoForm')">重置</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="修改密码" name="modifyPwd">
                            <div class="baseInfoForm">
                                <el-form :model="modifyPwdForm" :rules="modifyPwdFormRules" ref="modifyPwdFormRef" label-width="100px">
                                    <el-form-item label="原密码" prop="prePass">
                                        <el-input type="password" @input="e => modifyPwdForm.prePass = validForbid(e)" v-model="modifyPwdForm.prePass" clearable maxlength="16"/>
                                    </el-form-item>
                                    <el-form-item label="新密码" prop="newPass">
                                        <el-input type="password" @input="e => modifyPwdForm.newPass = validForbid(e)" v-model="modifyPwdForm.newPass" clearable maxlength="16"/>
                                    </el-form-item>
                                    <el-form-item label="确认密码" prop="checkPass">
                                        <el-input type="password" @input="e => modifyPwdForm.checkPass = validForbid(e)" v-model="modifyPwdForm.checkPass" clearable maxlength="16"/>
                                    </el-form-item>
                                    <el-form-item v-if="canSave">
                                        <el-button type="primary" class="iconfont icon-baocun" @click="submitForm('modifyPwdForm')">保存</el-button>
                                        <el-button class="iconfont icon-chongzhi" @click="resetForm('modifyPwdForm')">重置</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        name: "ChangePassword",
        setup(){
            let {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let validatePass = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else {
                    if (dataObj.modifyPwdForm.checkPass !== "") {
                        dataObj.modifyPwdFormRef.validateField("checkPass");
                    }
                    callback();
                }
            };
            let validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== dataObj.modifyPwdForm.newPass) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            let dataObj=reactive({
                baseInfoFormRef:null,
                modifyPwdFormRef:null,
                fullscreenLoading:false,
                activeName:'baseInfo',
                avatarImage: require('../../../assets/user.jpg'),//用户头像图片路径
                canSave:false,
                personalInfos:{},
                oraganizationBaseOtherInfos:{
                    corp:'',
                    position:'',
                    birthdate:'',
                    sex:''
                },
                baseInfoForm:{
                    nickName:'',
                    mobile:'',
                    email:''
                },
                modifyPwdForm: {
                    prePass: "",
                    newPass: "",
                    checkPass: ""
                },
                baseInfoFormRules:{
                    nickName: [
                        { required: true, message: '请输入昵称', trigger: 'blur' }
                    ]
                },
                modifyPwdFormRules: {
                    prePass: [{required: true,validator: validatePass, trigger: "blur"}],
                    newPass: [{required: true,validator: validatePass, trigger: "blur"}],
                    checkPass: [{required: true,validator: validatePass2, trigger: "blur"}]
                }
            })
            onMounted(async ()=>{
                let res = await utils.$$api.personalInfos({});
                if(res.result){
                    const personalInfos=JSON.parse(res.personalInfos);
                    dataObj.personalInfos=personalInfos;
                    dataObj.canSave=res.canSave;
                    dataObj.baseInfoForm.nickName=personalInfos.nickName;
                    dataObj.baseInfoForm.mobile=personalInfos.mobile;
                    dataObj.baseInfoForm.email=personalInfos.email;

                    dataObj.oraganizationBaseOtherInfos.corp=personalInfos.corp;
                    dataObj.oraganizationBaseOtherInfos.position=personalInfos.position;
                    dataObj.oraganizationBaseOtherInfos.birthdate=personalInfos.birthdate;
                    dataObj.oraganizationBaseOtherInfos.sex=personalInfos.sex;
                }
            })
            const doChangePwd=async()=>{
                dataObj.fullscreenLoading=true;
                dataObj.modifyPwdForm.prePass = utils.$$str.encrypt(dataObj.modifyPwdForm.prePass);
                dataObj.modifyPwdForm.newPass = utils.$$str.encrypt(dataObj.modifyPwdForm.newPass);
                dataObj.modifyPwdForm.checkPass = utils.$$str.encrypt(dataObj.modifyPwdForm.checkPass);
                let res = await utils.$$api.changePassword(dataObj.modifyPwdForm);
                if(res.result){
                    utils.$$tools.success();
                    resetForm("modifyPwdForm");
                }
                dataObj.fullscreenLoading=false;
            }
            const doChangeOtherInfo=async()=>{
                dataObj.fullscreenLoading=true;
                let res = await utils.$$api.changeOtherInfo(dataObj.baseInfoForm);
                if(res.result){
                    utils.$$tools.success();
                }
                dataObj.fullscreenLoading=false;
            }
            const submitForm=(formName)=>{
                if('modifyPwdForm'==formName){
                    dataObj.modifyPwdFormRef.validate(valid => {
                        valid ? doChangePwd() : false;
                    });
                }else{
                    dataObj.baseInfoFormRef.validate(valid => {
                        valid ? doChangeOtherInfo() : false;
                    });
                }

            }
            const resetForm=(formName)=>{
                if('modifyPwdForm'==formName){
                    dataObj.modifyPwdFormRef.resetFields();
                }else{
                    dataObj.baseInfoForm.nickName=dataObj.personalInfos.nickName;
                    dataObj.baseInfoForm.mobile=dataObj.personalInfos.mobile;
                    dataObj.baseInfoForm.email=dataObj.personalInfos.email;
                }
            }
            return{
                ...toRefs(dataObj),submitForm,resetForm
            }
        }
    });
</script>

<style scoped>
    .personInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
    }
    .oraganizationInfos{
        width: 350px;
        margin-right: 20px;
    }
    .otherInfos{
        flex: 2;
        margin-right: 10px;
    }
    .baseInfo ::v-deep(.el-card__header){
        padding: 15px 20px 5px 20px;
    }
    .oraganizationInfos ::v-deep(.el-card__body){
        padding:5px 20px 20px 0px;
        width: 100%;
    }
    .otherInfos ::v-deep(.el-card__body){
        padding:5px 20px 20px 20px;
        width: 100%;
    }

    .oraganizationBaseInfo{
        display: flex;
        flex-direction: column;
    }
    .picInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        /*background: linear-gradient(to bottom,#ecf0f1,#1abc9c,#bdc3c7);*/
        height: 200px;
    }
    .picInfo img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    .oraganizationBaseOtherInfos{
        padding: 10px;
        margin-top: 10px;
        font-size: 14px;
    }
    .oraganizationBaseOtherInfos::v-deep(.el-divider--horizontal){
        margin: 15px 0;
    }
    .oraganizationInfoItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 15px;
    }

    .baseInfoForm{
        padding-right: 40px;
    }
    .otherInfos ::v-deep(.el-form-item__label){
        font-size: 18px;
        /*font-weight: bold;*/
    }
</style>
